<template>
  <div class="passengerMonitorSetting">
    <div v-if="!dialogVisible2" class="setting">
      <div class="tabs">
        <el-tabs v-model="active" type="card" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in channelList"
            :key="index"
            :label="item.channelName"
            :name="item.channelNo"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="index">
        <el-radio-group v-model="radio" @input="changeTab">
          <el-radio-button :label="1">监测线路配置</el-radio-button>
          <el-radio-button :label="2">监测车辆配置</el-radio-button>
        </el-radio-group>
        <div v-if="radio == 1" class="content">
          <el-form :model="form1" label-width="90px" inline class="form-area">
            <el-form-item label="线路名称：">
              <el-select
                v-model="form1.lineId"
                placeholder="请选择线路"
                clearable
              >
                <el-option
                  v-for="(item, index) in bindLineList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否监测：">
              <el-select v-model="form1.openMonitor" clearable>
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              ><el-button
                type="primary"
                size="small"
                style="margin-left: 20px"
                @click="search1"
                >查询</el-button
              ><el-button
                v-if="listFind('添加')"
                type="primary"
                size="small"
                style="margin-left: 20px"
                @click="addBtn"
                >添加</el-button
              ></el-form-item
            >
          </el-form>
          <Table
            :title-name="titleName1"
            :table-data="tableData1"
            :operation="true"
            :operation-width="100"
          >
            <template slot-scope="{ scopeRow }">
              <el-button
                v-if="listFind('配置')"
                type="text"
                size="small"
                sort="primary"
                @click="configBtn(scopeRow)"
                >配置</el-button
              >
            </template>
          </Table>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination1.total"
              :page-size="pagination1.pageSize"
              :current-page="pagination1.currentPage"
              @size-change="onSizeChange1"
              @current-change="onCurrentChange1"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="content">
          <el-form :model="form2" label-width="90px" inline class="form-area">
            <el-form-item label="车牌号：">
              <el-select
                v-model="form2.licensePlateNumber"
                filterable
                remote
                reserve-keyword
                clearable
                :remote-method="queryCph"
                placeholder="请输入车牌号"
                @clear="form2.licensePlateNumber = null"
              >
                <el-option
                  v-for="(item, index) in cphList"
                  :key="index"
                  :label="item.licensePlateNumber"
                  :value="item.licensePlateNumber"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否监测：">
              <el-select v-model="form2.monitor" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                style="margin-left: 20px"
                @click="search2"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <Table
            :title-name="titleName2"
            :table-data="tableData2"
            :operation="true"
            :operation-width="100"
          >
            <template slot-scope="{ scopeRow }">
              <el-button
                type="text"
                size="small"
                sort="primary"
                :disabled="!scopeRow.takeVideoChanel"
                @click="editBtn(scopeRow)"
                >编辑</el-button
              >
            </template>
          </Table>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination2.total"
              :page-size="pagination2.pageSize"
              :current-page="pagination2.currentPage"
              @size-change="onSizeChange2"
              @current-change="onCurrentChange2"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="cameras">
      <div class="exit" @click="dialogVisible2 = false">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <el-form
        ref="takeForm"
        :model="takeForm"
        :rules="rules"
        label-width="130px"
      >
        <h3>车内监测</h3>
        <el-form-item label="请选择监测摄像头" prop="takeVideoChanelNumber">
          <div class="channelList">
            <div
              v-for="item in item.takeVideoChanel"
              :key="item"
              class="channel"
            >
              <el-radio
                v-model="takeForm.takeVideoChanelNumber"
                :disabled="item == takeForm.takeVideoChanelNumberCopilot"
                :label="item"
                >摄像头{{ item }}
              </el-radio>
              <div class="item" @click.self="changeChannel(item, 1)"></div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="图片裁剪">
          <div class="editImg">
            <div v-if="takeForm.takeVideoChanelNumberImage" class="cropperImg">
              <img :src="takeForm.takeVideoChanelNumberImage" alt="" />
              <canvas id="camera1" class="myCanvas"></canvas>
              <div class="el-icon-delete-solid" @click="deleteImage(1)"></div>
            </div>
            <div class="empty">
              <p class="label">请上传监测区域摄像头拍到的照片</p>
              <p class="label">并裁剪干扰车内人数识别的区域</p>
              <el-button
                type="primary"
                size="small"
                style="margin-top: 10px"
                @click="uploadPicture(1)"
                >上传图片</el-button
              >
              <p>为保护乘客隐私，示例图片人脸打码后再上传</p>
            </div>
          </div>
        </el-form-item>
        <h3>副驾驶监测</h3>
        <el-form-item
          label="请选择监测摄像头"
          prop="takeVideoChanelNumberCopilot"
        >
          <div class="channelList">
            <div
              v-for="item in item.takeVideoChanel"
              :key="item"
              class="channel"
            >
              <el-radio
                v-model="takeForm.takeVideoChanelNumberCopilot"
                :disabled="item == takeForm.takeVideoChanelNumber"
                :label="item"
                >摄像头{{ item }}
              </el-radio>
              <div class="item" @click.self="changeChannel(item, 2)"></div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="图片裁剪">
          <div class="editImg">
            <div
              v-if="takeForm.takeVideoChanelNumberCopilotImage"
              class="cropperImg"
            >
              <img :src="takeForm.takeVideoChanelNumberCopilotImage" alt="" />
              <canvas id="camera2" class="myCanvas"></canvas>
              <div class="el-icon-delete-solid" @click="deleteImage(2)"></div>
            </div>
            <div class="empty">
              <p class="label">请上传监测区域摄像头拍到的照片</p>
              <p class="label">并裁剪干扰车内人数识别的区域</p>
              <el-button
                type="primary"
                size="small"
                style="margin-top: 10px"
                autofocus
                @click="uploadPicture(2)"
                >上传图片</el-button
              >
              <p>为保护乘客隐私，示例图片人脸打码后再上传</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="default" @click="dialogVisible2 = false"
            >取消</el-button
          >
          <el-button type="primary" @click="onSave2">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      v-if="cropperModel"
      title="裁切封面"
      :visible.sync="cropperModel"
      width="950px"
      :close-on-click-modal="false"
      center
    >
      <cropper-image
        @uploadImgSuccess="handleUploadSuccess"
        @uploadImage="onSuccess"
        ref="child"
      >
      </cropper-image>
    </el-dialog>
    <el-dialog
      v-if="dialogVisible1"
      title="新增监测线路"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
    >
      <div class="lines">
        <div v-for="(item, index) in lineList" :key="index" class="item">
          <el-checkbox v-model="item.checked" :disabled="item.disabled">{{
            item.name
          }}</el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible1 = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="onSave1">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  getVehicleListAllAPI,
  linePageAPI,
  getVehicleList,
  queryPassengerDetectionSettingPage,
  savePassengerDetectionSetting,
  changeOpenMonitor,
  updateTakeVideoChanelNumber,
} from "@/api/lib/api.js";
import cropperImage from "./cropperImage/index.vue";
export default {
  components: { cropperImage },
  data() {
    return {
      channelList: [],
      active: "",
      radio: 1,
      form1: {
        lineId: null,
        openMonitor: null,
      },
      form2: {
        licensePlateNumber: null,
        monitor: null,
      },
      takeForm: {
        takeVideoChanelNumber: null,
        takeVideoChanelNumberImage: "",
        takeVideoChanelNumberImageResolution: null,
        takeVideoChanelNumberCut: [],
        takeVideoChanelNumberCopilot: null,
        takeVideoChanelNumberCopilotImage: "",
        takeVideoChanelNumberCopilotImageResolution: null,
        takeVideoChanelNumberCopilotCut: [],
      },
      rules: {
        takeVideoChanelNumber: {
          required: true,
          message: "请选择监测摄像头",
          trigger: "blur",
        },
        takeVideoChanelNumberCopilot: {
          required: true,
          message: "请选择监测摄像头",
          trigger: "blur",
        },
      },
      cropperModel: false,
      cameraType: null,
      lineList: [],
      cphList: [],
      titleName1: [
        {
          title: "线路名称",
          props: "lineName",
        },
        {
          title: "所属企业",
          props: "companyName",
        },
        {
          title: "乘客数监测",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.openMonitor === 0 ? false : true,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  changeOpenMonitor({
                    id: params.id,
                    openMonitor: params.openMonitor === 0 ? 1 : 0,
                  }).then((res) => {
                    this.renderData1();
                  });
                },
              },
            });
          },
        },
      ],
      tableData1: [],
      configLines: [],
      titleName2: [
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "所属线路",
          props: "lineNames",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "抓拍摄像头",
          SpecialJudgment: (res) => {
            if (res.takeVideoChanel) {
              if (
                res.takeVideoChanelNumber &&
                res.takeVideoChanelNumberCopilot
              ) {
                return `车内监测：摄像头${res.takeVideoChanelNumber}，副驾驶监测：摄像头${res.takeVideoChanelNumberCopilot}`;
              } else if (res.takeVideoChanelNumber)
                return `车内监测：摄像头${res.takeVideoChanelNumber}`;
              else if (res.takeVideoChanelNumberCopilot)
                return `副驾驶监测：摄像头${res.takeVideoChanelNumberCopilot}`;
              else return "-";
            } else {
              return "当前车辆无摄像头";
            }
          },
        },
      ],
      tableData2: [],
      pagination1: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pagination2: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible1: false,
      dialogVisible2: false,
      item: {},
      bindLineList: [],
      allList: [],
      imgUrl: "",
    };
  },
  mounted() {
    linePageAPI({
      currentPage: 1,
      pageSize: 10000,
    }).then((res) => {
      if (res.code == "SUCCESS") {
        this.allList = res.data.list;
        this.bindLineList = res.data.list.filter(
          (i) => i.channelNo == this.active
        );
      }
    });
    this.getQueryChannelList();
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data || [];
          this.active = res.data[0].channelNo;
          this.renderData1();
          if (!res.data.length) {
            this.$message.error("暂无权限，请联系管理员");
            return;
          }
        }
      });
    },
    handleClick(tab) {
      this.active = tab.name;
      this.bindLineList = this.allList.filter(
        (i) => i.channelNo == this.active
      );
      if (this.radio == 1) this.renderData1();
    },
    changeTab(val) {
      if (val == 1) {
        this.pagination1 = {
          currentPage: 1,
          pageSize: 10,
          total: 0,
        };
        this.renderData1();
      } else {
        this.pagination2 = {
          currentPage: 1,
          pageSize: 10,
          total: 0,
        };
        this.renderData2();
      }
    },
    configBtn(row) {
      this.$router.push({
        path: "/projectTravel/addMonitorAreaSetting",
        query: { id: row.id, lineId: row.lineId },
      });
    },
    editBtn(row) {
      if (row.takeVideoChanel) {
        this.item = row;
        this.dialogVisible2 = true;
        this.takeForm.takeVideoChanelNumber = row.takeVideoChanelNumber;
        this.takeForm.takeVideoChanelNumberCopilot =
          row.takeVideoChanelNumberCopilot;
        this.takeForm.takeVideoChanelNumberImage =
          row.takeVideoChanelNumberImage;
        this.takeForm.takeVideoChanelNumberCopilotImage =
          row.takeVideoChanelNumberCopilotImage;
        this.takeForm.takeVideoChanelNumberCut = row.takeVideoChanelNumberCut;
        this.takeForm.takeVideoChanelNumberCopilotCut =
          row.takeVideoChanelNumberCopilotCut;
        this.takeForm.takeVideoChanelNumberImageResolution =
          row.takeVideoChanelNumberImageResolution;
        this.takeForm.takeVideoChanelNumberCopilotImageResolution =
          row.takeVideoChanelNumberCopilotImageResolution;
      } else this.$message.warning("当前车辆无摄像头");
      this.$nextTick(() => {
        if (
          row.takeVideoChanelNumberCut &&
          row.takeVideoChanelNumberCut.length
        ) {
          const e = document.getElementById("camera1");
          const wh = JSON.parse(row.takeVideoChanelNumberImageResolution);
          this.tailorImage(row.takeVideoChanelNumberCut, e, wh);
        }
        if (
          row.takeVideoChanelNumberCopilotCut &&
          row.takeVideoChanelNumberCopilotCut.length
        ) {
          const e = document.getElementById("camera2");
          const wh = JSON.parse(
            row.takeVideoChanelNumberCopilotImageResolution
          );
          this.tailorImage(row.takeVideoChanelNumberCopilotCut, e, wh);
        }
      });
    },
    search1() {
      this.pagination1.currentPage = 1;
      this.pagination1.pageSize = 10;
      this.renderData1();
    },
    addBtn() {
      linePageAPI({
        currentPage: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.lineList = res.data.list
            .filter((i) => i.channelNo == this.active)
            .map((item) => {
              item.checked = false;
              item.disabled = false;
              return item;
            });
          if (!this.lineList.length) {
            return this.$message.warning("当前渠道下无线路");
          }
          const data = {
            currentPage: 1,
            pageSize: 1000,
            channelNo: this.active,
          };
          queryPassengerDetectionSettingPage(data).then((res2) => {
            if (res2.code === "SUCCESS") {
              const arr = res2.data.list.map((item) => item.lineId);
              this.dialogVisible1 = true;
              this.lineList.forEach((e) => {
                if (arr.some((i) => i == e.id)) {
                  e.disabled = true;
                  e.checked = true;
                }
              });
            }
          });
        }
      });
    },
    // 分页大小change
    onSizeChange1(val) {
      this.pagination1.pageSize = val;
      this.pagination1.currentPage = 1;
      this.renderData1();
    },
    // 分页页数改变change
    onCurrentChange1(val) {
      this.pagination1.currentPage = val;
      this.renderData1();
    },
    renderData1() {
      const data = {
        currentPage: this.pagination1.currentPage,
        pageSize: this.pagination1.pageSize,
        channelNo: this.active,
        ...this.form1,
      };
      queryPassengerDetectionSettingPage(data).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData1 = res.data.list;
          this.pagination1.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData1 = [];
        }
      });
    },
    queryLineList() {},
    search2() {
      this.pagination2.currentPage = 1;
      this.pagination2.pageSize = 10;
      this.renderData2();
    },
    // 分页大小change
    onSizeChange2(val) {
      this.pagination2.pageSize = val;
      this.pagination2.currentPage = 1;
      this.renderData2();
    },
    // 分页页数改变change
    onCurrentChange2(val) {
      this.pagination2.currentPage = val;
      this.renderData2();
    },

    changeChannel(val, type) {
      if (type == 1) {
        if (val == this.takeForm.takeVideoChanelNumber)
          this.takeForm.takeVideoChanelNumber = null;
        else if (val == this.takeForm.takeVideoChanelNumberCopilot) return;
        else this.takeForm.takeVideoChanelNumber = val;
      } else {
        if (val == this.takeForm.takeVideoChanelNumberCopilot)
          this.takeForm.takeVideoChanelNumberCopilot = null;
        else if (val == this.takeForm.takeVideoChanelNumber) return;
        else this.takeForm.takeVideoChanelNumberCopilot = val;
      }
    },
    deleteImage(type) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
        center: true,
      }).then(() => {
        if (type == 1) {
          this.takeForm.takeVideoChanelNumberImage = "";
          this.takeForm.takeVideoChanelNumberImageResolution = "";
          this.takeForm.takeVideoChanelNumberCut = [];
        } else {
          this.takeForm.takeVideoChanelNumberCopilotImage = "";
          this.takeForm.takeVideoChanelNumberCopilotImageResolution = "";
          this.takeForm.takeVideoChanelNumberCopilotCut = [];
        }
      });
    },
    renderData2() {
      getVehicleList({
        operationMode: [1],
        currentPage: this.pagination2.currentPage,
        pageSize: this.pagination2.pageSize,
        ...this.form2,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData2 = res.data.list;
          this.pagination2.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData2 = [];
        }
      });
    },
    uploadPicture(type) {
      this.cameraType = type;
      this.cropperModel = true;
    },
    onSuccess(data) {
      this.imgUrl = data;
    },
    handleUploadSuccess(data) {
      let { data1, data2 } = data;
      let w = (data1.x2 - data1.x1).toFixed();
      let h = (data1.y2 - data1.y1).toFixed();
      let arr = [
        {
          x: (data2.x1 - data1.x1).toFixed(),
          y: (data2.y1 - data1.y1).toFixed(),
        },
        {
          x: (data2.x2 - data1.x1).toFixed(),
          y: (data2.y1 - data1.y1).toFixed(),
        },
        {
          x: (data2.x2 - data1.x1).toFixed(),
          y: (data2.y2 - data1.y1).toFixed(),
        },
        {
          x: (data2.x1 - data1.x1).toFixed(),
          y: (data2.y2 - data1.y1).toFixed(),
        },
      ];
      if (this.cameraType == 1) {
        this.takeForm.takeVideoChanelNumberCut = arr;
        this.takeForm.takeVideoChanelNumberImageResolution = JSON.stringify({
          w,
          h,
        });
        this.takeForm.takeVideoChanelNumberImage = this.imgUrl;
        this.$nextTick(() => {
          const e = document.getElementById("camera1");
          this.tailorImage(arr, e, { w, h });
        });
      } else {
        this.takeForm.takeVideoChanelNumberCopilotCut = arr;
        this.takeForm.takeVideoChanelNumberCopilotImage = this.imgUrl;
        this.takeForm.takeVideoChanelNumberCopilotImageResolution =
          JSON.stringify({
            w,
            h,
          });
        this.$nextTick(() => {
          const e = document.getElementById("camera2");
          this.tailorImage(arr, e, { w, h });
        });
      }
      this.cropperModel = false;
    },
    tailorImage(arr, e, wh) {
      const r = 160 / wh.h;
      e.width = parseInt(wh.w * r);
      e.height = 160;
      const ctx = e.getContext("2d");
      const w = arr[1].x - arr[0].x;
      const h = arr[2].y - arr[0].y;
      ctx.strokeStyle = "#FF4500";
      ctx.lineWidth = 2;
      ctx.strokeRect(arr[0].x * r, arr[0].y * r, w * r, h * r);
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleListAllAPI({ cph: query }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.filter((i) => {
                return (
                  i.licensePlateNumber.indexOf(query) > -1 &&
                  i.operationMode.length == 1 &&
                  i.operationMode[0] == 1
                );
              });
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    onSave1() {
      let arr = [];
      this.lineList.forEach((e) => {
        if (e.checked && !e.disabled)
          arr.push({ lineId: e.id, channelNo: this.active });
      });
      if (!arr.length) {
        return this.$message.warning("请选择线路");
      }
      savePassengerDetectionSetting(arr).then((res) => {
        if (res.code == "SUCCESS") {
          this.dialogVisible1 = false;
          this.renderData1();
          this.$message.success("添加成功");
        }
      });
    },
    onSave2() {
      this.$refs.takeForm.validate((valid) => {
        if (valid) {
          const data = {
            id: this.item.id,
            data: {
              ...this.takeForm,
            },
          };
          updateTakeVideoChanelNumber(data).then((res) => {
            if (res.code == "SUCCESS") {
              this.dialogVisible2 = false;
              this.renderData2();
              this.$message.success("设置成功");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.passengerMonitorSetting {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;

  .setting {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tabs {
      flex-shrink: 0;
    }
    .index {
      flex: 1;
      padding: 10px 20px;
      .content {
        margin-top: 20px;
        .Table {
          margin-top: 10px;
        }
      }
    }
  }
  .cameras {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .exit {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);

      i {
        cursor: pointer;
      }
    }
    .channelList {
      display: flex;
      .channel {
        position: relative;
        .el-radio {
          margin: 10px;
        }
        .item {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .editImg {
      min-height: 160px;
      display: flex;
      .cropperImg {
        height: 200px;
        margin-left: 10px;
        position: relative;
        img {
          height: 100%;
        }
        .myCanvas {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
        .el-icon-delete-solid {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 30px;
          color: rgb(255, 255, 255);
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          &:hover {
            opacity: 1;
            transition: 0.5s;
          }
        }
      }
      .empty {
        width: 300px;
        height: 160px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #e2e2e2;
        font-size: 12px;
        color: #ccc;
        border-radius: 5px;
        flex-shrink: 0;
        .label {
          font-size: 13px;
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }
  .lines {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0 20px;
    .item {
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
  .canvas {
    width: 200px;
    height: 100px;
  }
}
</style>
