var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "passengerMonitorSetting" },
    [
      !_vm.dialogVisible2
        ? _c("div", { staticClass: "setting" }, [
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card" },
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.active,
                      callback: function ($$v) {
                        _vm.active = $$v
                      },
                      expression: "active",
                    },
                  },
                  _vm._l(_vm.channelList, function (item, index) {
                    return _c("el-tab-pane", {
                      key: index,
                      attrs: { label: item.channelName, name: item.channelNo },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "index" },
              [
                _c(
                  "el-radio-group",
                  {
                    on: { input: _vm.changeTab },
                    model: {
                      value: _vm.radio,
                      callback: function ($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("监测线路配置"),
                    ]),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("监测车辆配置"),
                    ]),
                  ],
                  1
                ),
                _vm.radio == 1
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-form",
                          {
                            staticClass: "form-area",
                            attrs: {
                              model: _vm.form1,
                              "label-width": "90px",
                              inline: "",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "线路名称：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择线路",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form1.lineId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form1, "lineId", $$v)
                                      },
                                      expression: "form1.lineId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.bindLineList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否监测：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.form1.openMonitor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form1, "openMonitor", $$v)
                                      },
                                      expression: "form1.openMonitor",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "是", value: 1 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "否", value: 0 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.search1 },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _vm.listFind("添加")
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "20px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: { click: _vm.addBtn },
                                      },
                                      [_vm._v("添加")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("Table", {
                          attrs: {
                            "title-name": _vm.titleName1,
                            "table-data": _vm.tableData1,
                            operation: true,
                            "operation-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ scopeRow }) {
                                  return [
                                    _vm.listFind("配置")
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                              sort: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.configBtn(scopeRow)
                                              },
                                            },
                                          },
                                          [_vm._v("配置")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            232058644
                          ),
                        }),
                        _c(
                          "div",
                          { ref: "pagination", staticClass: "pagination" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                background: "",
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.pagination1.total,
                                "page-size": _vm.pagination1.pageSize,
                                "current-page": _vm.pagination1.currentPage,
                              },
                              on: {
                                "size-change": _vm.onSizeChange1,
                                "current-change": _vm.onCurrentChange1,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-form",
                          {
                            staticClass: "form-area",
                            attrs: {
                              model: _vm.form2,
                              "label-width": "90px",
                              inline: "",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      clearable: "",
                                      "remote-method": _vm.queryCph,
                                      placeholder: "请输入车牌号",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        _vm.form2.licensePlateNumber = null
                                      },
                                    },
                                    model: {
                                      value: _vm.form2.licensePlateNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form2,
                                          "licensePlateNumber",
                                          $$v
                                        )
                                      },
                                      expression: "form2.licensePlateNumber",
                                    },
                                  },
                                  _vm._l(_vm.cphList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.licensePlateNumber,
                                        value: item.licensePlateNumber,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否监测：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.form2.monitor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form2, "monitor", $$v)
                                      },
                                      expression: "form2.monitor",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "是", value: true },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "否", value: false },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.search2 },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("Table", {
                          attrs: {
                            "title-name": _vm.titleName2,
                            "table-data": _vm.tableData2,
                            operation: true,
                            "operation-width": 100,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ scopeRow }) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          size: "small",
                                          sort: "primary",
                                          disabled: !scopeRow.takeVideoChanel,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editBtn(scopeRow)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            364983282
                          ),
                        }),
                        _c(
                          "div",
                          { ref: "pagination", staticClass: "pagination" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                background: "",
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.pagination2.total,
                                "page-size": _vm.pagination2.pageSize,
                                "current-page": _vm.pagination2.currentPage,
                              },
                              on: {
                                "size-change": _vm.onSizeChange2,
                                "current-change": _vm.onCurrentChange2,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "cameras" },
            [
              _c(
                "div",
                {
                  staticClass: "exit",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = false
                    },
                  },
                },
                [_c("i", { staticClass: "iconfont its_ba_fanhui" })]
              ),
              _c(
                "el-form",
                {
                  ref: "takeForm",
                  attrs: {
                    model: _vm.takeForm,
                    rules: _vm.rules,
                    "label-width": "130px",
                  },
                },
                [
                  _c("h3", [_vm._v("车内监测")]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "请选择监测摄像头",
                        prop: "takeVideoChanelNumber",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "channelList" },
                        _vm._l(_vm.item.takeVideoChanel, function (item) {
                          return _c(
                            "div",
                            { key: item, staticClass: "channel" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled:
                                      item ==
                                      _vm.takeForm.takeVideoChanelNumberCopilot,
                                    label: item,
                                  },
                                  model: {
                                    value: _vm.takeForm.takeVideoChanelNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.takeForm,
                                        "takeVideoChanelNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "takeForm.takeVideoChanelNumber",
                                  },
                                },
                                [_vm._v("摄像头" + _vm._s(item) + " ")]
                              ),
                              _c("div", {
                                staticClass: "item",
                                on: {
                                  click: function ($event) {
                                    if ($event.target !== $event.currentTarget)
                                      return null
                                    return _vm.changeChannel(item, 1)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "图片裁剪" } }, [
                    _c("div", { staticClass: "editImg" }, [
                      _vm.takeForm.takeVideoChanelNumberImage
                        ? _c("div", { staticClass: "cropperImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.takeForm.takeVideoChanelNumberImage,
                                alt: "",
                              },
                            }),
                            _c("canvas", {
                              staticClass: "myCanvas",
                              attrs: { id: "camera1" },
                            }),
                            _c("div", {
                              staticClass: "el-icon-delete-solid",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteImage(1)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "empty" },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("请上传监测区域摄像头拍到的照片"),
                          ]),
                          _c("p", { staticClass: "label" }, [
                            _vm._v("并裁剪干扰车内人数识别的区域"),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadPicture(1)
                                },
                              },
                            },
                            [_vm._v("上传图片")]
                          ),
                          _c("p", [
                            _vm._v("为保护乘客隐私，示例图片人脸打码后再上传"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("h3", [_vm._v("副驾驶监测")]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "请选择监测摄像头",
                        prop: "takeVideoChanelNumberCopilot",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "channelList" },
                        _vm._l(_vm.item.takeVideoChanel, function (item) {
                          return _c(
                            "div",
                            { key: item, staticClass: "channel" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled:
                                      item ==
                                      _vm.takeForm.takeVideoChanelNumber,
                                    label: item,
                                  },
                                  model: {
                                    value:
                                      _vm.takeForm.takeVideoChanelNumberCopilot,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.takeForm,
                                        "takeVideoChanelNumberCopilot",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "takeForm.takeVideoChanelNumberCopilot",
                                  },
                                },
                                [_vm._v("摄像头" + _vm._s(item) + " ")]
                              ),
                              _c("div", {
                                staticClass: "item",
                                on: {
                                  click: function ($event) {
                                    if ($event.target !== $event.currentTarget)
                                      return null
                                    return _vm.changeChannel(item, 2)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "图片裁剪" } }, [
                    _c("div", { staticClass: "editImg" }, [
                      _vm.takeForm.takeVideoChanelNumberCopilotImage
                        ? _c("div", { staticClass: "cropperImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.takeForm
                                  .takeVideoChanelNumberCopilotImage,
                                alt: "",
                              },
                            }),
                            _c("canvas", {
                              staticClass: "myCanvas",
                              attrs: { id: "camera2" },
                            }),
                            _c("div", {
                              staticClass: "el-icon-delete-solid",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteImage(2)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "empty" },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("请上传监测区域摄像头拍到的照片"),
                          ]),
                          _c("p", { staticClass: "label" }, [
                            _vm._v("并裁剪干扰车内人数识别的区域"),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                type: "primary",
                                size: "small",
                                autofocus: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadPicture(2)
                                },
                              },
                            },
                            [_vm._v("上传图片")]
                          ),
                          _c("p", [
                            _vm._v("为保护乘客隐私，示例图片人脸打码后再上传"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible2 = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSave2 },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.cropperModel
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "裁切封面",
                visible: _vm.cropperModel,
                width: "950px",
                "close-on-click-modal": false,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.cropperModel = $event
                },
              },
            },
            [
              _c("cropper-image", {
                ref: "child",
                on: {
                  uploadImgSuccess: _vm.handleUploadSuccess,
                  uploadImage: _vm.onSuccess,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "新增监测线路",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "lines" },
                _vm._l(_vm.lineList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: item.disabled },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSave1 },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }