<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :centerBox="option.centerBox"
          :high="option.high"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        >
        </vue-cropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">选择封面</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="selectImg($event)"
          />
        </div>
        <div>
          <el-button
            size="mini"
            :disabled="option.img ? false : true"
            type="success"
            @click="uploadImg()"
            >上传封面 <i class="el-icon-upload"></i
          ></el-button>
        </div>
      </div>
    </div>
    <!--预览效果图-->
    <div class="show-preview">
      <div :style="previews.div" class="preview">
        <img :src="previews.url" :style="previews.img" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { uploadFileAPI } from "@/api/lib/api.js";
import config from "@/api/static/host.js";
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  data() {
    return {
      previews: {},
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        centerBox: true, //截图框是否被限制在图片里面
        high: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "contain", //图片默认渲染方式
      },
      imgUrl: config.imgURL,
    };
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      let formdata = new FormData();
      formdata.append("files", file, "image.jpeg");
      uploadFileAPI(formdata).then((res) => {
        if (res.code == "SUCCESS") {
          this.$emit("uploadImage", this.imgUrl + res.data[0]);
          if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
            this.$message.error("图片类型要求：jpeg、jpg、png");
            return false;
          }
          //转化为blob
          let reader = new FileReader();
          reader.onload = (e) => {
            let data;
            if (typeof e.target.result === "object") {
              data = window.URL.createObjectURL(new Blob([e.target.result]));
            } else {
              data = e.target.result;
            }
            this.option.img = data;
          };
          //转化为base64
          reader.readAsDataURL(file);
        } else {
          this.$message.error("上传图片失败");
        }
      });
    },
    //上传图片
    uploadImg() {
      const data1 = this.$refs.cropper.getImgAxis();
      const data2 = this.$refs.cropper.getCropAxis();
      this.$emit("uploadImgSuccess", { data1, data2 });
    },
  },
};
</script>

<style scoped lang="scss">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 400px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
.footer-btn {
  margin-top: 10px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }
}
</style>
