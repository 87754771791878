var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cropper-content" }, [
    _c("div", { staticClass: "cropper-box" }, [
      _c(
        "div",
        { staticClass: "cropper" },
        [
          _c("vue-cropper", {
            ref: "cropper",
            attrs: {
              img: _vm.option.img,
              outputSize: _vm.option.outputSize,
              outputType: _vm.option.outputType,
              info: _vm.option.info,
              canScale: _vm.option.canScale,
              autoCrop: _vm.option.autoCrop,
              canMove: _vm.option.canMove,
              canMoveBox: _vm.option.canMoveBox,
              centerBox: _vm.option.centerBox,
              high: _vm.option.high,
              infoTrue: _vm.option.infoTrue,
              maxImgSize: _vm.option.maxImgSize,
              enlarge: _vm.option.enlarge,
              mode: _vm.option.mode,
            },
            on: { realTime: _vm.realTime, imgLoad: _vm.imgLoad },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "footer-btn" }, [
        _c("div", { staticClass: "scope-btn" }, [
          _c("label", { staticClass: "btn", attrs: { for: "uploads" } }, [
            _vm._v("选择封面"),
          ]),
          _c("input", {
            staticStyle: { position: "absolute", clip: "rect(0 0 0 0)" },
            attrs: {
              type: "file",
              id: "uploads",
              accept: "image/png, image/jpeg, image/gif, image/jpg",
            },
            on: {
              change: function ($event) {
                return _vm.selectImg($event)
              },
            },
          }),
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  disabled: _vm.option.img ? false : true,
                  type: "success",
                },
                on: {
                  click: function ($event) {
                    return _vm.uploadImg()
                  },
                },
              },
              [_vm._v("上传封面 "), _c("i", { staticClass: "el-icon-upload" })]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "show-preview" }, [
      _c("div", { staticClass: "preview", style: _vm.previews.div }, [
        _c("img", {
          style: _vm.previews.img,
          attrs: { src: _vm.previews.url },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }